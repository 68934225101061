@font-face {
  font-family: "classgaramond";
  src: url("./assets/fonts/classgaramond/ClassicalGaramondBT.ttf") format("ttf"),
    url("./assets/fonts/classgaramond/ClassicalGaramondBT.woff") format("woff"),
    url("./assets/fonts/classgaramond/ClassicalGaramondBT.woff2")
      format("woff2");
}
@font-face {
  font-family: "classgaramondBold";
  src: url("./assets/fonts/classgaramond/ClassicalGaramondBoldBT.ttf")
      format("ttf"),
    url("./assets/fonts/classgaramond/ClassicalGaramondBoldBT.woff")
      format("woff"),
    url("./assets/fonts/classgaramond/ClassicalGaramondBoldBT.woff2")
      format("woff2");
}
@font-face {
  font-family: "classgaramonditalic";
  src: url("./assets/fonts/classgaramond/ClassicalGaramondItalicBT.ttf")
      format("ttf"),
    url("./assets/fonts/classgaramond/ClassicalGaramondItalicBT.woff")
      format("woff"),
    url("./assets/fonts/classgaramond/ClassicalGaramondItalicBT.woff2")
      format("woff2");
}
@font-face {
  font-family: "scalasans";
  src: url("./assets/fonts/scalasans/ScalaSansOT-Regular.woff") format("woff");
}
html {
  background-color: black;
}
html,
body,
#root,
.App,
.container {
  height: 100% !important;
}
nav {
  position: fixed !important;
  width: 100%;
}
p {
  letter-spacing: 2px;
}
.App {
  font-family: "classgaramond";
  color: white;
}
.italic {
  font-family: "classgaramonditalic";
}
.letterSpacing {
  letter-spacing: 6px;
}
.bg-home {
  background-image: url("./assets/bg-home.svg");
  background-position: center center;
  background-repeat: no-repeat;
}
.bg-two {
  background-image: url("./assets/bg-two.svg");
  background-position: center center;
  background-repeat: no-repeat;
}
h1 {
  font-size: 88px !important;
}
button {
  border: 1px solid white;
  background-color: inherit;
  color: white;
  padding: 5px 65px 5px 65px;
  font-family: "scalasans" !important;
  letter-spacing: 4px;
}
button:hover {
  background-color: white;
  color: black;
}
.ellipse {
  background-image: url("./assets/ellipse-home.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}
.recuadro {
  background-image: url("./assets/recuadro.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 400px;
  width: auto;
  position: relative;
}
.recuadro-2 {
  height: 550px;
  position: relative;
}
.recuadro .containerButton,
.recuadro-2 .containerButton {
  position: absolute;
  bottom: 0px;
  width: 100%;
  left: 0;
}
.recuadro-masculine {
  background-image: url("./assets/masculine-bg.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}
.recuadro-feminine {
  background-image: url("./assets/feminine-bg.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}
.recuadro-feminine .containerButton,
.recuadro-masculine .containerButton {
  bottom: 90px;
}
.recuadro-gradient {
  border-image: linear-gradient(
      to top,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.1)
    )
    2 !important;
  border-width: 1px;
  border-style: solid;
}
.recuadro-daytime {
  background-image: linear-gradient(
      180deg,
      rgba(19, 37, 37, 0.06) 0%,
      #0b1112 60%,
      #0f191c 100%
    ),
    url("./assets/daytime.svg");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 30%;
}
.recuadro-evening {
  background-image: linear-gradient(
      180deg,
      rgba(19, 37, 37, 0.06) 0%,
      #0b1112 60%,
      #0f191c 100%
    ),
    url("./assets/evening.svg");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 30%;
}
.recuadrohor {
  border-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.4),
      rgba(255, 255, 255, 0)
    )
    2 !important;
  border-width: 1px;
  border-style: solid;
}
.containerButton button {
  padding: 5px 25px 5px 25px;
}
.button-2 {
  padding: 5px 5px 5px 5px;
  width: 92%;
}
.f1 {
  font-size: 20px !important;
}
.f2 {
  font-size: 32px !important;
  line-height: 34px;
}
.f3 {
  font-size: 14px !important;
}
.f4 {
  font-size: 44px !important;
  line-height: 44px;
}
.h1discover {
  font-size: 150px !important;
  z-index: 1;
  position: relative;
  font-family: "classgaramondBold";
}
.mt90 {
  margin-top: 90%;
}
.mnegprod {
  margin: -13px;
  margin-left: -32px;
  position: absolute;
}
.photoDiscover {
  position: absolute;
  right: 0;
  top: -15px;
  z-index: 0;
}
.photoDiscover img {
  mask-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.8) 80%,
    rgba(0, 0, 0, 0)
  );
}

.image {
  height: "100%";
  width: auto;
  object-fit: cover;
}

/* Animaciód dels 3 punts */
.dot-elastic {
  position: relative;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #fafafa;
  color: #fafafa;
  animation: dot-elastic 1s infinite linear;
}
.dot-elastic::before,
.dot-elastic::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-elastic::before {
  left: -10px;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #fafafa;
  color: #fafafa;
  animation: dot-elastic-before 1s infinite linear;
}
.dot-elastic::after {
  left: 10px;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #fafafa;
  color: #fafafa;
  animation: dot-elastic-after 1s infinite linear;
}

@keyframes dot-elastic-before {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1.5);
  }
  50% {
    transform: scale(1, 0.67);
  }
  75% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes dot-elastic {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 1.5);
  }
  75% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes dot-elastic-after {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 0.67);
  }
  75% {
    transform: scale(1, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}

::-webkit-scrollbar {
  width: 2px;
}

::-webkit-scrollbar-thumb {
  background: white;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-toggle {
  padding: 10px 20px;
  background-color: transparent;
  color: #fff;
  cursor: pointer;
  font-family: "scalasans";
}
